@charset "utf-8";
/*COMPONENTES*/
.arrow-circle,
.arrow-circle--white {
	font-size: 12px;
	padding: 12px;
	border-radius: 23px;
	transition: all .5s;
}

.arrow-circle,
.arrow-circle--white:hover,
.arrow-circle--white:focus {
	color: #fff;
	background-color: #695433;
}

.arrow-circle--white,
.arrow-circle:hover,
.arrow-circle:focus {
	color: #695433;
	background-color: #fff;
	box-shadow: 0px 0px 12px rgba(0,0,0,0.3);
}

.arrow-circle:hover,
.arrow-circle--white:hover,
.arrow-circle:focus,
.arrow-circle--white:focus {
	cursor: pointer;
	transition: all .5s;
}

/* ## buttons -> [btn, btn-transparent, btn-border] */

.btn, .btn-transparent,
.btn-border, 
.btn-voltar, 
.btn-dark, 
.form-control,
.btn-fala-cidadao {
	font: bold 12px "Lato";
	text-transform: uppercase;
	text-align: center;
	border-radius: unset;
	padding-top: 20px;
	padding-bottom: 20px;
	transition: all .5s;
	display: block;
}

.btn {
	color: #454545 !important;
	background-color: #ededed;
}

.btn:hover,
.btn:focus {
	background-color: #DFDFDF;
	transition: all .5s;
}

.btn-transparent {
	color: #fff;
	background-color: rgba(0,0,0,0);
	border: 2px solid #fff;
}

.btn-transparent:hover,
.btn-transparent:focus {
	background-color: rgba(0,0,0,.5);
}

.btn-border {
	color: #2e3192;
	background-color: #fff;
	border: 1px solid #2e3192;
}

.btn-border:hover,
.btn-border:focus {
	color: #fff;
	background-color: #2e3192;
	transition: all .5s;
	cursor: pointer;
}

.btn-voltar {
	color: #b1b1b1;
	background-color: #ededed;
	border: none;
}

.btn-voltar i {
	margin-right: 10px;
}

[class*=btn-filtrar] {
    margin: 0;
    border-radius: unset;
    transition: all .5s;
    display: block;
    border: 1px solid #dbdbdb;
    text-align: left;
    position: relative;
    overflow: hidden;
}

[class*=btn-filtrar]:after {
	content: "\f175";
	color: #695433;
	font-size: 15px;
	font-family: FontAwesome;
	text-align: center;
	position: absolute;
	right: 5px;
	top: 0px;
	z-index: 1;
	height: 100%;
	padding: 18px;
	pointer-events: none;
	box-sizing: border-box;
	width: 10%;
}

[class*=btn-filtrar] select {
	font: bold 12px "Lato";
	color: #b1b1b1;
    text-transform: uppercase;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;
	height: 100%;
	padding: 20px;
	display: block;
}

[class*=btn-filtrar] option {
	padding: 30px;
	display: block;
}

.btn-dark {
	color: #fff;
	background-color: #2e3192;
	border: none;
}

.btn-dark:hover {
	cursor: pointer;
}

.btn-fala-cidadao {
	color: #fff;
	background-color: #12c4ff;
}

.btn-fala-cidadao:hover,
.btn-fala-cidadao:focus {
	color: #fff;
	background-color: #80E1FF;
}

/* ## end buttons */

/* filtros-bts */
/* linha de botões de filtros superior direito das paginas que possuem paginação e listagens */

.filtros-btns {
	float: right;
	display: flex;
	flex-direction: row;
}

.filtros-btns > * {
	margin-left: 30px;
}

.filtros-btns .btn-voltar,
.filtros-btns .btn-filtrar {
	width: 170px;
}

.filtros-btns .btn-filtrar--lg {
	width: 270px;
}
/* filtros-bts */

/*
menu lateral
e listagem estilo menu
*/
.menu-lateral__titulo{
	color: #fff;
	background-color: #695433;
}

.listagem-stl-menu__titulo {
	color: #fff;
	background-color: #6b6b6b;
}

.menu-lateral__titulo,
.menu-lateral li,
.listagem-stl-menu__titulo,
.listagem-stl-menu li
 {
	font: bold 12px 'Lato';
	text-transform: uppercase;
	padding: 20px;
	list-style: none;
}

.menu-lateral ul,
.listagem-stl-menu ul {
	padding-left: 0;
}

.menu-lateral li,
.listagem-stl-menu li {
	color: #6b6b6b;
	border-bottom: 1px solid #EDEDED;
}

.menu-lateral li.active {
	font-weight: 900;
	color: #0654a7;
}

.menu-lateral li:hover,
.menu-lateral li:focus {
	color: #695433;
	transition: all .5s;
}
/*
end menu lateral
e listagem estilo menu
*/

/* LISTAGEM2 */
.listagem2 li {
	font: 800 12px 'Lato';
	color: #929292;
	list-style: none;
	padding: 15px;
	border-bottom: 1px solid #ededed;
}
/* END LISTAGEM2 */

/*## galeria de fotos carousel */
.galeria-itens .slick-list {
	width: calc(100% + 30px);
	height: auto;
	margin-left: -15px;
	box-sizing: border-box;
	border-right: 30px solid #fff;
	border-left: 15px solid #fff;
}

.galeria-itens .arrow-circle--white {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 16px;
	border-radius: 50px;
	font-size: 20px;
}

.galeria-itens .fa-long-arrow-left {
	left: -25px;
	z-index: 1;
}

.galeria-itens .fa-long-arrow-right {
	right: -10px;
	z-index: 1;
}

.galeria-itens__item {
	margin: 0 15px;
	height: auto !important;
}
/* ## end galeria de fotos carousel */

/* accordion */
.accordion {
	width: 100%;
}

.accordion .card-block {
	padding: 30px 25px;
}
.accordion .card {
	background-color: #EDEDED;
	border-radius: 0;
	border: none;
	margin-bottom: 10px;
}

.accordion .card:hover {
	cursor: pointer;
}

.accordion .card-header {
	padding: 20px;
	border: none;
}

.accordion .card-header.active {
	background-color: #787878;
}

.accordion .card-header.active h5 {
	color: #fff;
}

.accordion .card-header .fa {
	font-size: 17px;
	font-weight: bolder;
	float: right;
}

.accordion .card-header h5 {
	color: #6b6b6b;
}

.accordion .collapse,
.accordion .collapsing {
	background-color: #fff;
}
/* end accordion */

/* ACCORDION COM SUB-ACCORDION, LINKS E STATUS */
.accordion .card-block-outer {
	padding: 10px 0 0 0;
}
.accordion-inner .card-header {
	background-color: #fff;
	border: 1px solid #e6e6e6;
}
.accordion-inner .card-header.active {
	background-color: #fff;
	border-color: #787878;
}

.accordion-inner .card-header.active h5,
.accordion-inner .card-header.active i {
	color: #787878;
}

.accord-status {
	font: 800 12px 'Lato';
	text-transform: uppercase;
}

.accord-status .aberto {
	color: #11ac5d;
}

.accord-status .fechado {
	color: #225387;
}
/* END ACCORDION COM SUB-ACCORDION, LINKS E STATUS */

/* DOWNLOAD-BOX */
.download-box {
	font: bold 12px 'myriad';
}
/* DOWNLOAD-BOX */

/* box info button */

.box-info-row > * {
	flex: 1 25%;
	max-width: 25%;
}

.box-info-btn {
	height: 100px;
	padding-left: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px;
}

.box-info-btn:hover,
.box-info-btn:focus {
	background-color: #f26522;
}

.box-info-btn:hover *,
.box-info-btn:focus * {
	color: #fff !important;
}

.box-info-btn:hover object,
.box-info-btn:focus object {
    filter: brightness(10);
}

.box-info-texto {
	margin-left: 10px;
}

.box-info-titulo,
.box-info-subtitulo {
	font: bold 18px 'Lato';
	text-transform: uppercase;
}

.box-info-titulo {
	color: #f26522;
}

.box-info-subtitulo {
	font-size: 12px;
	color: #6b6b6b; 
}
/* end box info button */

/* breadcrumbs */
.breadcrumbs__container {
	font: bold 12px 'Lato';
	color: #dbdbdb;
	border-bottom: 1px solid #dbdbdb;
}

.breadcrumbs__container .row {
	padding-top: 20px;
    padding-bottom: 12px;
}

.breadcrumbs {
	padding-left: 10px;
	display: inline-block;
}
.breadcrumbs li {
	color: #2e3192;
	display: inline-block;
}

.breadcrumbs li:last-child {
	color: #695433;
}

.breadcrumbs li:not(:last-child)::after {
	content: '>';
}
/* end breadcrumbs */

/* img */
.img-border {
	border: 1px solid #6b6b6b;
}
/* end img */

/* contatos */
.contatos {
	font: bold 12px 'Lato';
	color: #0054A2;
}
.contatos i {
	font-size: 12px;
	color: #695433;
	margin-right: 5px;
}
.contatos-item {
	font-weight: 800;
	margin-right: 2.5rem;
	white-space: nowrap;
	line-height: 3;
}
.contatos-item--site {
	color: #695433;
	text-decoration: underline;
}
/* end contatos */

/* listagem */
.listagem {
	list-style: none;
}

.listagem li {
	border-bottom: 1px solid #dbdbdb;
	padding: 30px 0 35px;
}
/* end listagem */

/* paginacao */
.paginacao {
	position: relative;
	width: 100%;
}
.paginacao-links {
	margin: 0 auto;
	text-align: center;
	padding: 6px;
}
.paginacao-links li {
	font: bold 12px 'Lato';
	display: inline-block;
	margin: 0 4px;
	height: 24px;
	width: 24px;
	padding: 4px 0;
}

.paginacao-links li.active {
	color: #fff;
	background-color: #f26522;
}
.paginacao .arrow-circle--white {
	position: absolute;
}
.paginacao .fa-long-arrow-left {
	left: 0;
}
.paginacao .fa-long-arrow-right {
	right: 0;
}
/* end paginacao */

/* box-banner-btn */
.box-banner {
	color: #fff;
	padding: 6rem 7rem 5.6rem 7rem;
	background-size: cover;
	background-repeat: no-repeat;
}

.box-banner-prefeitura {
	background-image: url('../../../dist/images/onde-comer-bg.png');
}

.box-banner-o-que-fazer {
	background-image: url('../../../dist/images/onde-ficar-bg.png');
}

.box-banner-cidade {
	background-image: url('../../../dist/images/o-que-fazer-bg.png');
}

.box-banner__titulo {
	font: bold 3.2rem 'Lato';
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 3rem;
}

.box-banner__titulo .small {
	font-family: 'black_diamonds';
	text-transform: lowercase;
	font-size: 5.6rem;
	line-height: 0.9;
}

.box-banner .btn-transparent {
	border-width: 1px;
}

[class*=__row] {
	justify-content: space-between;
}

.box-banner__row-2 .box-banner {
	width: calc(50% - 15px);
	height: 320px;
}

.box-banner__row-3 .box-banner {
	width: calc(33% - 15px);
	height: 220px;
	padding: 4rem 5rem;
}

.box-banner__row-3 .box-banner__titulo {
	font-size: 1.8rem;
}

.box-banner__row-3 .box-banner__titulo .small {
	font-size: 5.2rem;
}
/* end box-banner-btn */

/* formulario */
.invalid {
	border-color: red;
}
.formulario .row {
	margin-left: 0;
	margin-right: 0;
	display: flex;
	justify-content: space-between;
}

.form-control {
	padding-left: 20px;
	text-align: left;
}

.form-control::placeholder {
	color: #c4c4c4;
}

.formulario .half {
	width: calc(50% - 15px);
}
/* end formulario */

/* BOX-CALENDARIO */

.calendario__datas-box {
	background-color: #ededed;
	padding: 26px 9px 26px 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 30px 0;
	width: 270px;
	min-height: 133px;
}

.calendario__data {
	font: bold 24px 'myriad';
	text-align: center;
	color: #fff;
	line-height: 0.6;
	background-color: #2E3192;
	padding: 11px;
	margin-right: 16px;
}

.calendario__data .small {
	font-size: 15px;
	text-transform: capitalize;
}

.calendario__data-titulo,
.calendario__data-info {
	color: #003874;
}

.calendario__data-titulo {
	font: bold 14px 'Lato';
	text-transform: uppercase;
}

.calendario__data-info {
	font: 12px 'myriad';
}

/* END BOX-CALENDARIO */

/* LINK COM SETA */
.arrow-link {
	padding: 7px 4.5rem 7px 0;
	width: fit-content;
	display: inline-block;
}

.arrow-link-nome,
.arrow-link-url {
	font: 800 12px 'Lato';
	text-transform: uppercase;
	padding-left: 10px;
}
.arrow-link-nome {
	color: #f26522;
}
.arrrow-link-url {
	color: #003874;
}

@media screen and (max-width: 991px) {
	.arrow-link {
		padding-right: 0;
	}	
}
/* END LINK COM SETA */

/* BOX-NOTÍCIA */
.box-noticia {
	margin: 0;
}

.box-noticia:not(:first-child) {
	margin: 30px 0;
}

.box-noticia .img-label {
	/*position: relative;*/
	width: fit-content;
	/*top: auto;*/
	/*left: auto;*/
	display: inline-block;
	margin-right: 14px;
}

.box-noticia__imagem {
	width: 25.034483%;
	max-width: 31.0344828%;
	margin-right: 28px;
	display: inline;
	object-fit: cover;
	height: unset;
}

.box-noticia__texto-container {
	width: 65.5172414%;
	max-width: 65.5172414%;
}

.box-noticia__texto-container h4 {
	margin-top: 22px;
}

.box-noticia__ler-mais	 {
	font: bold 1.2rem 'Lato';
	color: #695433;
	text-transform: uppercase;
	margin-left: 10px;
}

@media screen and (max-width: 767px) {
	.box-noticia__imagem {
		width: 100%;
		max-width: 100%;
		padding: 15px 0;
		margin-right: 0;
	}

	.box-noticia__texto-container {
		width: 100%;
		max-width: 100%;
	}
}

/* END BOX-NOTÍCIA */

/* LABELS */
.img-label {
	font: 15px 'myriad';
	padding: 11px 20px 8px 13px;
	display: inline-block;
}

.img-label.comunidade {
	background-color: #006631;
	color: #fff;
}
.img-label.mercado {
	background-color: #ffc20e;
	color: #000;
}
.img-label.esporte {
	background-color: #007bff;
	color: #fff;
}
.img-label.financas {
	background-color: #f26522;
	color: #fff;
}
.img-label.saude {
	background-color: #7b0a6b;
	color: #fff;
}
/* END LABELS */

/* CAROUSEL COM MINIATURAS */
.slick-initialized > i.fa-long-arrow-left {
	font-size: 20px;
	left: -20px;
}

.slick-initialized > i.fa-long-arrow-right {
	right: -20px;
	font-size: 20px;
}

.carousel-navs img {
	margin: 0 10px;
}

.slick-initialized .slick-slide {
	outline: none;
}
/* END CAROUSEL COM MINIATURAS */

/* CLIQUE NA FOTO PARA AMPLIAR */
.ampliar {
	font: bold 12px 'Lato';
	color: #003874;
	text-transform: uppercase;
	padding: 10px 0;
}
/* END CLIQUE NA FOTO PARA AMPLIAR */

/* FOLDER-BROWSER */
.folder-browser {
	list-style: none;
}

.folder-browser > li {
	font: 800 12px 'Lato';
	color: #003874;
	text-transform: uppercase;
	padding: 5px 0;
}

.folder-browser > li:before {
	content: '+';
	background-color: #dedede;
	/* padding: 2px; */
	/* box-sizing: border-box; */
	width: 14px;
	display: inline-flex;
	height: 14px;
	justify-content: center;
	align-items: center;
}

.folder-browser > li.open:before {
	content: '-';
}

.folder-browser ul {
	display: none;
}

.folder-browser .line {
	color: #adadad;
	letter-spacing: -1.1px;
	padding: 10px;
}

.folder-browser ul.open {
	display: block;
}

/* END FOLDER-BROWSER */

/* BOX INFO BUTTON */

.box-info-row > * {
	-webkit-box-flex: 1;
	    -ms-flex: 1 25%;
	        flex: 1 25%;
	max-width: 25%;
}

.box-info-btn {
	height: 100px;
	padding-left: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin: 10px;
}

.box-info-btn:hover,
.box-info-btn:focus {
	background-color: #2e3192;
}

.box-info-btn:hover *,
.box-info-btn:focus * {
	color: #fff !important;
}

.box-info-btn:hover object,
.box-info-btn:focus object {
    -webkit-filter: brightness(10);
            filter: brightness(10);
}

.box-info-texto {
	margin-left: 10px;
}

.box-info-titulo,
.box-info-subtitulo {
	font: bold 18px 'Lato';
	text-transform: uppercase;
}

.box-info-titulo {
	color: #2e3192;
}

.box-info-subtitulo {
	font-size: 12px;
	color: #6b6b6b; 
}

.svg-icon object {
	max-width: 47px;
	max-height: 57px;
	fill: green;
	stroke: green;
}
/* END BOX INFO BUTTON */

/* REDES SOCIAIS */
.redes-sociais img {
	margin-right: 8px;
}

.redes-sociais img:hover,
.redes-sociais img:focus {
	opacity: 0.7;
}
/* FIM REDES SOCIAIS */

/*END COMPONENTES*/


/* HEADER E MENU */
.header {
	background: linear-gradient(180deg, #fff 72%, #03AEC8 23%);
	/* 	position: relative; */
}

.header .fa-bars {
	font-size: 40px;
	display: none;
	position: absolute;
	right: 10%;
	top: 50%;
	transform: translateY(-50%);
}

.menu-responsivo {
	position: absolute;
	background-color: #fff;
	height: auto;
	padding: 15px;
	top: 89px;
	width: 100%;
	min-width: 100%;
	/* border-bottom: 3px solid #888; */
	box-shadow: 0px 33px 53px #000;
	max-height: calc(100vh - 91px);
	overflow: auto;
}

.menu-responsivo .menu__input-search,
.menu-responsivo .menu__link-portal {
	margin-right: 0;
	margin-bottom: 10px;
}

.menu-responsivo .menu__secundario,
.menu-responsivo .menu__primario {
	flex-wrap: wrap;
	max-width: 100%;
	white-space: unset;
	text-align: center;
}

.menu-responsivo .menu__info {
	color: #000;
	display: flex;
	padding: 15px;
}

.menu-responsivo .menu__primario li {
	width: 100%;
	display: block;
}

.menu-responsivo .menu__primario > li:hover,
.menu-responsivo .menu__primario > li:focus {
	background-color: initial;
	color: #fff !important;
}

.menu-responsivo .menu__primario li:not(:last-child) {
	border-bottom: 2px solid #fff;
}

.menu__dropdown-mobile {
	color: #003874;
	background-color: #fff;
	width: 131%;
	position: relative;
	left: -36px;
	bottom: -16px;
	padding-left: 0;
	display: none;
	/* border: none; */
}

.menu__dropdown-mobile li {
	padding: 15px 35px;
}

.menu__dropdown-mobile li:not(:last-child) {
	border-bottom: 1px solid #003874 !important;
}

.menu-responsivo .menu__secundario > li {
	margin: 5px !important;
}

.menu-responsivo .menu__link-portal > * {
	display: inline-block;
}

.menu-hidden {
	opacity: 0;
	transition: all 1s;
	display: none;
	z-index: -1;
}

.menu-visible {
	opacity: 1;
	transition: all 1s;
	z-index: 16;
}

/* ACESSO RÁPIDO */
.acesso-rapido {
	display: none;
	overflow: hidden;
	/* margin-top: -30px; */
	position: relative;
}

.acesso-rapido li{
	list-style: none;
}

.acesso-rapido-seta {
	color: #2e3192;
	font-size: 20px;
	background-color: #fff;
	padding: 18px 25px;
	position: absolute;
	right: 25px;
	top: -20px;
	display: none;
}

.acesso-rapido-seta.active {
	display: block;
}
/* ACESSO RÁPIDO */

/*MENU*/

.menu-row {
	padding-top: 40px;
}

.menu__primario {
	background-color: #2E3192;
	padding: 0;
	width: 100%;
	text-align: center;
	margin-bottom: 0;
	white-space: nowrap;
	max-width: 100%;
}

.menu__primario > li {
	font: 18px 'Lato';
	color: #fff;
	text-transform: uppercase;
	background-color: #2E3192;
	display: inline-block;
}

.menu__primario > li.active * {
	font-weight: bold;
	color: #d2ac67;
}

.menu__primario > li:hover,
.menu__primario > li:focus,
.menu__primario > li.open {
	background-color: #fff;
	/* font-weight: bold; */
	color: #695433 !important;
	cursor: pointer;
	height: 110%;
	position: relative;
	bottom: 5px;
	box-shadow: 0px 0px 26px #444;
}

.link {
	padding: 15px 40px;
	box-sizing: border-box;
	position: relative;
	display: block;
}

/* .menu__primario > li:hover .scaling-menu {
	width: 110%;
	background-color: #fff;
	box-shadow: 10px 0 0 0 #000;
	z-index: 1;
} */

.link-with-dropdown.open [data-dropdown] {
	display: block;
}

.menu__primario > li:hover [data-dropdown],
.menu__primario > li:focus [data-dropdown] {
	display: block;
}

[data-dropdown] {
	color: #003874;
	background-color: #fff;
	height: auto;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	box-shadow: 0px 14px 18px #444;
	display: none;
}

[data-dropdown] ul {
	padding-left: 0;
}

[data-dropdown] li {
	font-size: 14px;
	font-weight: 700;
	/* padding: 15px 40px; */
	list-style: none;
	text-align: left;
	transition: all .5s;
}

[data-dropdown] li:hover,
[data-dropdown] li:focus {
	color: #fff;
	background-color: #2E3192;
	transition: all .5s:;
}

.menu__secundario {
	padding: 0;
	white-space: nowrap;
}

.menu__secundario > li {
	font: bold 12px 'Lato';
	color: #6c6c6c;
	list-style: none;
	text-transform: uppercase;
	display: inline-block;
	width: 123px;
	padding: 15px 0;
	text-align: center;
	border-top: 3px solid;
}
.menu__secundario > li:not(:first-child):not(:last-child) {
	margin-left: 6.4px;
	margin-right: 6.4px;
}

.menu__secundario > li:nth-child(1) {border-color: #006631;}
.menu__secundario > li:nth-child(2) {border-color: #695433;}
.menu__secundario > li:nth-child(3) {border-color: #B1151A;}
.menu__secundario > li:nth-child(4) {border-color: #003874;}
.menu__secundario > li:nth-child(5) {border-color: #7b0a6b;}
.menu__secundario > li:nth-child(6) {border-color: #695433;}

.menu__secundario > li:nth-child(1):hover,
.menu__secundario > li:nth-child(1):focus {background-color: #006631;}

.menu__secundario > li:nth-child(2):hover,
.menu__secundario > li:nth-child(2):focus {background-color: #695433;}

.menu__secundario > li:nth-child(3):hover,
.menu__secundario > li:nth-child(3):focus {background-color: #B1151A;}

.menu__secundario > li:nth-child(4):hover,
.menu__secundario > li:nth-child(4):focus {background-color: #003874;}

.menu__secundario > li:nth-child(5):hover,
.menu__secundario > li:nth-child(5):focus {background-color: #7b0a6b;}

.menu__secundario > li:nth-child(6):hover,
.menu__secundario > li:nth-child(6):focus {background-color: #695433;}


.menu__secundario > li:hover,
.menu__secundario > li:focus {
	color: #fff;
	transition: all .5s;
	cursor: pointer;
}

.menu__container-menu-sec {
	max-width: 100%;
	margin: 0;
}

.menu__utility {
	color: #fff;
	max-width: 100%;
	display: flex;
	flex-direction: row;
	margin: 0;
}

.menu__nome-prefeitura {
	font: 3rem 'Gotham';
	color: #000;
}

.menu__input-search input,
.menu__link-portal {
	color: #000;
	font: 12px 'Lato';
	text-transform: uppercase;
	border: 1px solid #dbdbdb;
}

.menu__link-portal {
	padding: 18px 16px;
	text-align: center;
	transition: all .5s;
}

.menu__input-search input {
	width: 100%;
	padding: 19px 16px;
}

.menu__input-search,
.menu__link-portal {
	flex: 3;
	margin-right: 30px;
}

.menu__link-portal:hover,
.menu__link-portal:focus {
	background-color: #525252;
}

.menu__input-search {
	position: relative;
}

.menu__input-search .fa-search {
	font-size: 15px;
	color: #695433;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.menu__input-search .fa-search:hover,
.menu__input-search .fa-search:focus {
	cursor: pointer;
}

.menu__input-search input::placeholder {
	color: #000;
	font-weight: bold;
}

.menu__link-portal {
	color: #fff;
	background-color: #525252;
}

.font-size-controls {
	
	flex: 1;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.font-size-controls > * {
	font: bold 12px 'Lato';
	color: #2E3192;
	background: transparent;
	border: none;
}

.font-size-controls:hover,
.font-size-controls:focus {
	cursor: pointer;
}

.menu__logotipo-container {
	max-width: 100%;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
}

.menu_info-row {
	padding: 20px 0;
}

.scaling-menu {
	height: 100%;
	width: 100%;
	background-color: #003874;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	opacity: 0;
}

.menu__primario-texto {
	/* color: #fff; */
	/* z-index: 2; */
}
/*MENU*/
/* END HEADER E MENU */

/*BANNERS*/
.carousel-control-next-icon,
.carousel-control-prev-icon {
	width: 5.5rem;
	height: 5.5rem;
}

.carousel-caption {
	height: 100%;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
}

.carousel-indicators {
	align-items: center;
}

.carousel-indicators li {
	width: 8px;
	height: 8px;
	border-radius: 7px;
	background-color: #fff;
	margin: 0 5px;
}

.carousel-indicators li.active {
	background-color: #f26522;
	border: 2px solid #fff;
	box-sizing: content-box;
}
/*BANNERS*/

/* FOOTER */

.footer__links-section {
	font: 12px 'Lato';
	color: #fff;
	text-transform: uppercase;
	background-color: #2e3192;
	padding-top: 6rem;
	padding-bottom: 7rem;
}

.footer__links-section h6 {
	margin-bottom: 15px;
}

.footer__links-section [class^="col-"]:nth-child(2n-1) ul {
	height: 50%;
}

.footer__links-section ul {
	padding-left: 0;
	list-style: none;
}

.footer__links-section li {
	margin-bottom: 5px;
}

.rodape__fone-box {
	font-weight: bold;
	margin-top: 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rodape__fone-box img {
	margin-right: 10px;
}

.rodape__fone-number {
	font-size: 23px;
	font-weight: bold;
	padding-top: 13px;
}

.footer__dados-section {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.footer__dados-section .row {
	align-items: center;
}

.footer__dados-section [class^=col-] {
	padding: 0;
}

.footer__dados-section [class^=col-]:nth-child(2) {
	padding-left: 25px;
}

.menu__nome-prefeitura .menu__nome-prefeitura {
	display: block;
	padding-left: 15px;
}

.footer__links-section [class^="col-"]:nth-child(1n) ul {
	height: auto;
}


.footer__dados-section .fake-div {
	display: none;		
}

.footer__dados-section .row {
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
}

.footer__dados-section div[class^="col-"]:nth-child(1n) {
	text-align: center;
	padding: 0;
}
/* END FOOTER */

/* banner-secundario */
.banner-secund {
	background-image: url('../../../dist/images/banner-bg.png');
	background-size: cover;
	padding-top: 3.6rem;
	padding-bottom: 3.6rem;
}

.banner-secund__texto {
	font: 3.6rem 'black_diamonds';
	color: #fff;
}
/* end banner-secundario */

/* nome dos politicos */
.politico__nome,
.politico__cargo {
    font: 800 12px 'Lato';
    color: #f26522;
    text-transform: uppercase;
}

.politico__cargo {
    color: #2e3192;
    margin-bottom: 1.5rem;
}

.politico__nome {
    color: #695433;
}
/* nome dos politicos */

/*MEDIAS*/
/*XL BOOTSTRAP GRID*/
@media screen and (max-width: 1200px) {
	.banner__titulo {
		font-size: 5rem;
	}

	.banner__subtitulo {
		font-size: 4.5rem;
	}

	.menu__primario > li {
		font-size: 1.4rem;
		padding: 9px 18px;
	} 

	.menu__secundario > li {
		width: 117.9px;
	}

	.menu-fake-div {
		display: none;
	}
	
	/* box-banner	 */
	.box-banner__row-3 .box-banner {
		padding: 4rem 2rem;
	}
	/* end box-banner	 */

	/* box-info */
	.box-info-row > * {
		max-width: 33%;
		flex: 1 33%;
	}
	/* end box-info */
}

@media screen and (max-width: 1024px) {
	.galeria-itens .fa-long-arrow-left {
		left: 0;
	}
	
	.galeria-itens .fa-long-arrow-right {
		right: 0;
	}

	.galeria-itens .slick-list {
		border-right-width: 15px;
	}
}

/*LG BOOTSTRAP GRID*/
@media screen and (max-width: 992px) {
	.menu__utility,
	.menu__container-menu-sec,
	.menu__primario,
	.menu_info-row {
		display: none;
	}

	.header {
		background: none;
		background-color: #fff;
		z-index: 16;
	}

	.menu-row {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
	}

	.menu__logo-prefa {
		height: 50px;
	}

	.menu__logotipo-container {
		width: 100%;
	}

	.header .fa-bars {
		display: block;
	}

	.menu-responsivo * {
		display: block;
	}

	.menu-responsivo__row {
		display: flex;
	}

	.menu-responsivo__row > * {
		flex-direction: column;
	}

	/* 	footer */
	.footer__links-section [class^="col-"]:nth-child(1n) ul {
		height: auto;
	}


	.footer__dados-section .fake-div {
		display: none;		
	}

	.footer__dados-section .row {
		justify-content: space-between;
	}

	.footer__dados-section div[class^="col-"]:nth-child(1n) {
		text-align: center;
		padding: 0;
	}
	/* 	end footer */

	/* BOX-BANNER */
	.box-banner__row-3 .box-banner__titulo .small {
		font-size: 4.4rem;
		margin-top: 15px;
	}

	/* END BOX-BANNER */

	/* BOX-INFO */
	.box-info-row > * {
		flex: 1 50%;
		max-width: 50%;
	}
	/* END BOX-INFO */
}

@media screen and (max-width: 940px) {
	.carousel-control-next-icon,
	.carousel-control-prev-icon {
		width: 3rem;
		height: 3rem;
	}
}

/*MD BOOTSTRAP GRID*/
@media screen and (max-width: 767px) {
	.banner__titulo {
		font-size: 3rem;
	}

	.banner__subtitulo {
		font-size: 3rem;
	}

	/* box-banner */
	.box-banner .small {
		font-size: 5.6rem;
	}

	[class*=__row] {
		flex-direction: column;
		padding-left: 15px;
		padding-right: 15px;
	}

	[class*=__row] .box-banner {
		padding: 6rem 7rem 5.6rem 7rem;
	}

	[class*=__row] .box-banner {
		width: 100%;
		margin-bottom: 20px;
	}

	.box-banner__row-3 .box-banner__titulo {
		font-size: 1.8rem;
	}
	
	.box-banner__row-3 .box-banner__titulo .small {
		font-size: 5.2rem;
	}
	/* box-banner */
	
	/* footer */
	.footer__dados-section div[class^="col-"]:nth-child(-n + 2) {
		margin-bottom: 30px;
	}
	/* end footer */

	/* 	filtors-btns */
	.filtros-btns {
		flex-direction: column
	}

	.filtros-btns > [class*=btn-] {
		width: 170px;
		margin-left: 0;
		margin-bottom: 20px;
	}
	/* 	filtors-btns */
}

/*SM BOOTSTRAP GRID*/
@media screen and (max-width: 576px) {
	.banner__titulo {
		display: none;
	}

	.banner__subtitulo {
		font-size: 2.5rem;
	}

	.menu__nome-prefeitura	{
		font-size: 2.5rem;
	}
	
	/* footer */
	.footer__dados-section div[class^="col-"]:nth-child(1n) {
		margin-bottom: 30px;
	}
	/* end footer */

	/* filtros-btns */
	.filtros-btns {
		width: 100%;
	}

	.filtros-btns > *:first-child {
		margin-left: 0;
	}

	.filtros-btns > *:last-child {
		margin-right: 0;
	}
	/* end filtros-btns */

	/* 	paginacao */
	.paginacao .fa-long-arrow-right {
		right: 15px;
	}

	.paginacao .fa-long-arrow-left {
		left: 15px;
	}
	/* 	paginacao */

	/* box-info */
	.box-info-row > * {
		flex: 1 100%;
		max-width: 100%;
	}
	/* end box-info */
}

@media screen and (max-width: 450px) {
    .formulario .half {
        width: 100%;
    }

    .formulario .half:nth-of-type(2) {
        margin-top: 1rem;
    }
}

/*DAQUI PRA BAIXO ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€šÃ‚Â° XS BOOTSTRAP GRID*/

/*MOBILE*/
@media screen and (max-width: 425px) {
	.carousel-indicators {
		display: none; 
	}

	.carousel-caption {
		top: 0;
	}

	.menu__nome-prefeitura	{
		font-size: 2rem;
	}
}
/*MEDIAS*/
